import { Component,OnInit,NgZone } from '@angular/core';
import { LocalStorageService } from '../services/user-info/user-info.service';
import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  events = [];
  message = '';
  public messages: any[];
  rfidInfo: string;
  sse$: Observable<any>;
  station_id: string;
  url:string;
  constructor(
    private localStorageService: LocalStorageService,
    private navCtrl: NavController,
    private ngZone: NgZone,
    private http: HttpClient
    ) {
      let loginStatus = this.localStorageService.getLoginStatus();
      if(loginStatus) {
        this.station_id = this.localStorageService.getStationId();
        this.url = "https://api-station.instaswap.in/sse?station_id="+this.station_id;
      }  else {
        this.url = "https://api-station.instaswap.in/sse";
        this.navCtrl.navigateForward('/login', {});
      }
  }
  ngOnInit() {

    // const eventSource = new EventSource(this.url);
    // eventSource.onmessage = (event) => {
    //   console.log(event.data);
    //   let redirect = JSON.parse(event.data).redirect;
    //   if(redirect) {
    //     console.log("hello",redirect)
    //   }
    //     };
    

  }
  

}
