import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getDriverId() {
    return localStorage.getItem('driver_id');
  }
  getUserObject() {
    return JSON.parse(localStorage.getItem('userObject'));
  }
  getDriverObject() {
    return localStorage.getItem('driverObj');
  }
  getStationId() {
    const userObj = JSON.parse(localStorage.getItem('userObject'));
    return userObj.qr_id;
  }

  getLoginStatus() {
    const loginStatus = JSON.parse(localStorage.getItem('loginStatus'));
    return loginStatus;
  };
  getRechargeObject() {
    const rechargeObject = JSON.parse(localStorage.getItem('rechargeObj'));
    return rechargeObject;
  }
}

