import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'rfid',
    loadChildren: () => import('./rfid/rfid.module').then( m => m.RfidPageModule)
  },
  {
    path: 'driver-info',
    loadChildren: () => import('./driver-info/driver-info.module').then( m => m.DriverInfoPageModule)
  },
  {
    path: 'swap-input',
    loadChildren: () => import('./swap-input/swap-input.module').then( m => m.SwapInputPageModule)
  },
  {
    path: 'swap-output',
    loadChildren: () => import('./swap-output/swap-output.module').then( m => m.SwapOutputPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'phonepe',
    loadChildren: () => import('./phonepe/phonepe.module').then( m => m.PhonepePageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
